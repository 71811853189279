@import url('https://fonts.googleapis.com/css2?family=Hammersmith+One&family=PT+Sans+Caption:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hammersmith+One&family=Playfair+Display:wght@400;700&family=Poppins:wght@400;500;600&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');

 /* Landigng Page */
/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

body {
  font-family: 'Poppins', sans-serif;
  color: #333;
}

.wrap {
  display: flex;
  /* height: 100vh; */
  width: 100%;
  background-color: rgba(34, 86, 120, 1);
  padding: 20px;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  text-align: left;
  color: white;
}

.img-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-image {
  width: 80%;
  height: auto;
  margin-top: 80px;
}

.wlcm {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-bottom: 20px;
}

#wlcm-top {
  font-size: 46px;
  color: #EEAF83;
  margin-bottom: 20px;
  margin-top: 40px;
}

#wlcm-btm {
  font-size: 58px;
  color: #EEAF83;
  margin-top: 20px;
}

.wlcm-saytum {
  font-family: 'Hammersmith One', sans-serif;
  font-weight: 500;
  margin: 0;
  line-height: 1;
  margin-top: 20px;
}


.newlist {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 20px;
  line-height: 1.6;
  padding-top: 20px;
  padding-bottom: 20px;
}

.newlist li {
  padding-left: 2em;
  position: relative;
  margin-bottom: 10px;
  text-align: left;
  padding-left: 60px;
}

.nl-p1::before {
  content: "\f5e4";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  color: #ffffff;
  left: 10px;
  font-size: 0.9em;
  top: 2px;
}

.nl-p2::before {
  content: "\f4d3";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  color: #ffffff;
  left: 10px;
  font-size: 0.9em;
  top: 2px;
}

.nl-p3::before {
  content: "\f48b";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  color: #ffffff;
  left: 10px;
  font-size: 0.9em;
  top: 2px;
}

.extra {
  font-size: 22px;
  color: #EEAF83;
  max-width: 600px;
  margin-top: 10px;
}

.btns {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  padding-top: 30px;
}

.button-17 {
  background: linear-gradient(45deg, #00A4BD, #007E9E);
  border-radius: 4px;
  border: none;
  color: white;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-size: 16px;
  padding: 12px 30px;
  cursor: pointer;
  text-transform: uppercase;
  transition: background 0.3s ease, color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 200px;
}

.button-17:hover {
  background: #1877f2;
  color: white;
}

.button-17:active {
  transform: translateY(1px);
}

#signup {
  background: #fb923c;
  color: linear-gradient(45deg, #00A4BD, #007E9E);
  border: #333 2px;
}

#signup:hover {
  background: #1877f2;
  color: white;
}

/* // About CEO */
.about-ceo {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: linear-gradient(to right, #d8e5f3, #bfd5ef);
    background-attachment: fixed;
    background-size: cover;
}


.ceo-left {
  flex: 1;
}

.ceo-left h1 {
    font-size: 70px;
    font-family: "Hammersmith One", sans-serif;
    font-weight: 400;
    margin-top: 80px;
    margin-left: 200px;
    color: #225678;
    position: relative;
}

.ceo-name {
    font-size: 30px;
    margin-left: 200px;
    margin-top: -50px;
    line-height: 1.6;
    color: #EEAF83;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
}

.ceo-qoute{
    font-size: 18px;
    margin-left: 200px;
    margin-top: 30px;
    max-width: 400px;
    color: #696969;
    /* color: rgb(54, 51, 51); */
    /* -webkit-text-stroke: black;
    -webkit-text-stroke-width: 1px; */
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

.ceo-details{
    color: black;
    font-size: 18px;
    margin-top: 5px;
    max-width: 400px;
    /* color: rgb(54, 51, 51); */
    /* -webkit-text-stroke: black;
    -webkit-text-stroke-width: 1px; */
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

#qoute-left{
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: #ffffff;
    font-size: 50px;
    margin-right:20px;
}

#qoute-right{
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: #7f7a7a;
    font-size: 50px;
    position: absolute;
    margin-left: 20px;
}

.ceo-content{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 0;
  width: 100%;
}


/* .ceo-image {
  position: relative;
  width: 100%;
  height: 100%;
}

.image-wrapper {
    height: 450px;
    width: 350px;
    margin-right: 150px;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
} */

.ceo-image {
  width: 550px;
  height: 550px;
}

.image-wrapper {
  margin-right: 0;
  margin-top: -100px;
}


/* // about Us */

.abtus {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background: linear-gradient(to right, #d8e5f3, #bfd5ef);
}

.about-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1500px;
    background: #fff;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
    margin: 20px;
}

.abtleft,
.abtright {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.abt-img {
    width: 500px;
    height: 500px;
    position: relative;
    object-fit: cover;
    opacity: 0.8;
}

.abtright {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.abtright h1 {
    font-size: 48px;
    font-family: "Hammersmith One", sans-serif;
    font-weight: 400;
    color: #225678;
    margin-bottom: 20px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.abtright p {
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    color: #333;
    margin: 0;
    line-height: 1.5;
    max-width: 600px;
}

/*Insight */
.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-height: 800px;
  background: linear-gradient(to right, #d8e5f3, #bfd5ef);
}

.ins-head-container {
  text-align: center;
  margin-bottom: 10px;
}

.ins-head {
  font-size: 48px;
  font-family: "Hammersmith One", sans-serif;
  font-weight: 400;
  color: #225678;
  margin-bottom: 0;
  margin-top: 20px;
  text-shadow: none;
  position: relative;
}

.insights-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 500px;
  max-width: 1500px;
  background: #fff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
  position: relative;
  align-items: center;
}

.toggle-container {
  align-self: flex-start;
  margin-top: 30px;
  margin-left: 100px;
}


.insights-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 500px;
  margin-top: -45px;
}

.insights-left {
  flex: 1;
  /* padding: 20px; */
}

.insights-right {
  flex: 1;
  padding: 20px;
}

.ins-head {
  font-size: 48px;
  font-family: "Hammersmith One", sans-serif;
  font-weight: 400;
  color: #225678;
  margin-bottom: 20px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.ins-head1,.ins-head2,.ins-head3,.ins-head4{
  font-size: 30px;
  font-family: "Hammersmith One", sans-serif;
  font-weight: 400;
  color: #225678;
  margin-bottom: 20px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.ins-desc1,.ins-desc2,.ins-desc3,.ins-desc4{
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  max-width: 500px;
}

.image-container{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* min-height: 500px; */
}

.image-container img{
  
  box-shadow: 20px 15px 20px rgba(0, 0, 0, 0.4);
}

.ins-list {
  background: transparent;
  margin-top: 70px;
  color: black;
}

.ins-images{
  margin-top: 50px;
}

.ins-tabs {
  list-style-type: none;
  padding: 0;
  color: black;
}

.ins-tabs .tablinks {
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
  padding: 10px;
  padding-left: 20px;
  color: black;
}

.ins-images{
  margin-top: 0px;
}

.ins-tabs .tablinks::before {
  content: '';
  position: absolute;
  left: 0;
  top: 10%;
  width: 3px;
  height: 50%;
  background-color: #225678;
  transition: height 0.3s, top 0.3s;
}

.ins-tabs .tablinks.active::before,
.ins-tabs .tablinks:hover::before {
  top: 0;
  height: 100%;
}

.ins-tabs .tablinks h3 {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
}
.ins-tabs .tablinks p {
  margin: 5px 0 0;
  font-size: 16px;
  display: none;
}
.ins-tabs .tablinks.active p {
  display: block;
}
.tab-container {
  position: relative;
}
.tabcontent {
  display: none;
}
.tabcontent img {
  max-width: 100%;
  height: auto;
}
.tabcontent.active {
  display: block;
}

/* // Video Section */
.vdo-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  background: rgba(34, 86, 120, 1);
  animation: fadeInBackground 0.5s ease-in-out forwards;
}

@keyframes fadeInBackground {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

.vdo-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  background: linear-gradient(to right, #d8e5f3, #bfd5ef);
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
  animation: slideInRows 0.5s ease-in-out forwards;
}

@keyframes slideInRows {
  from {
      opacity: 0;
      transform: translateY(-20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

.vdo-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.vdo-main {
  flex: 2;
  margin-right: 20px;
  border-radius: 6px;
  overflow: hidden;
  background: rgba(255, 255, 255, 1);
  width: 100%;
  padding-top: 10px;
}

.vdo-side {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
}

.video-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-in-out;
}

.vdo-thumb1,
.vdo-thumb2 {
  width: 100%;
  height: auto;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.fade-out {
  opacity: 0;
}

.fade-in {
  opacity: 1;
}

.vdo-thumb2-wrapper {
  width: 100%;
  height: auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.vdo-thumb2 {
  width: 100%;
}

.play-button {
  position: absolute;
  width: 55px;
  color: #ff0000;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.5s ease-in-out, transform 0.1s ease-in-out;
}


.video-wrapper:hover .play-button {
  opacity: 1;
}

.vdo-smallrow {
  flex-direction: column;
  background: white; 
  margin: 20px 0;
  align-items: flex-start;
  border-radius: 6px;
  overflow: hidden;
  width: 100%;
  display: flex;
  transition: all 0.5s ease-in-out;
}

.vdo-maintxt, .vdo-sidetxt {
  text-align: left;
  margin-left: 20px;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.vdo-sidetxt {
  font-family: "Hammersmith One", sans-serif;
  font-size: 16px;
  font-weight: 400;
  max-width: 300px;
  margin-left: 10px;
}

.vdo-maintxt {
  font-family: "Hammersmith One", sans-serif;
  font-size: 24px;
  max-width: 450px;
  margin-left: -240px;
  font-weight: 500;
}

@keyframes slideOutLeft {
  from {
      opacity: 1;
      transform: translateX(0);
  }
  to {
      opacity: 0;
      transform: translateX(-100%);
  }
}

@keyframes slideInRight {
  from {
      opacity: 0;
      transform: translateX(100%);
  }
  to {
      opacity: 1;
      transform: translateX(0);
  }
}

@keyframes slideOutLeftSmall {
  from {
      opacity: 1;
      transform: translateX(0);
  }
  to {
      opacity: 0;
      transform: translateX(-40%);
  }
}

@keyframes slideInRightSmall {
  from {
      opacity: 0;
      transform: translateX(100%);
  }
  to {
      opacity: 1;
      transform: translateX(0);
  }
}

.slide-out-left {
  animation: slideOutLeft 0.5s forwards;
}

.slide-in-right {
  animation: slideInRight 0.5s forwards;
}

.slide-out-left-small {
  animation: slideOutLeftSmall 0.5s forwards;
}

.slide-in-right-small {
  animation: slideInRightSmall 0.5s forwards;
}


/* Adjustments for mobile screens */
@media screen and (max-width: 768px) {
  .vdo-row {
      flex-direction: column; /* Stack columns in smaller screens */
      padding: 20px; /* Adjust padding for smaller screens */
  }

  .vdo-main {
      width: 100%; /* Full width for main video in smaller screens */
      margin-right: 0; /* No margin on smaller screens */
  }

  .vdo-side {
      width: 100%; /* Full width for side video in smaller screens */
      margin-top: 20px; /* Add some space between main and side video */
  }

  .video-wrapper {
      max-width: 100%; /* Make sure videos don't overflow */
  }

  .vdo-maintxt, .vdo-sidetxt {
      margin: 10px 0; /* Adjust margin for text */
  }

  .vdo-maintxt {
      margin-left: 0; /* Reset left margin for main text */
      font-size: 15px;
      text-align: center
  }
  
  .vdo-thumb2-wrapper {
      display: block;
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%; /* Maintain aspect ratio for thumbnails */
  }

  .vdo-thumb2 {
      position: absolute;
      width: 100%;
      height: 100%;
  }

  .play-button {
      width: 40px; /* Adjust play button size */
      padding-top: 50px;
      margin-left: 100px;
  }

  .vdo-smallrow {
      align-items: center; /* Center items in smaller screens */
  }

  .vdo-sidetxt {
      max-width: 100%; /* Adjust width of side video text */
  }

  .vdo-maintxt {
      max-width: 100%; /* Adjust width of main video text */
  }
}


/* // Mission */
.misandvis {
  background:  rgba(34,86,120, 1);
  padding-bottom: 60px;
  width: 100%;
  text-align: center;
}

.mission-header h1 {
  font-size: 48px;
  font-family: "Hammersmith One", sans-serif;
  font-weight: 400;
  color: #E0E0E0;
  padding-top: 40px;
}

.mission-header p {
  text-align: center;
  margin: 30px auto;
  max-width: 800px;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: #E0E0E0;
}

.flex-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 50px;
}

.ndflex {
  background-color: #E8E8E8;
  width: 90%;
  max-width: 400px;
  padding: 20px;
  margin: 20px;
  border-radius: 18px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.ndflex:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.miss-img {
  height: 120px;
  width: 120px;
  margin: 0 auto;
  display: block;
}

.miss-head {
  font-family: "Hammersmith One", sans-serif;
  font-weight: 400;
  font-size: 25px;
  margin-top: 20px;
  color: #225678;
}

.miss-content {
  color: #333333;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 18px;
  margin-top: 20px;
  padding: 0 20px;
}

/* Responsive code */
/* Adjustments for mobile screens */

@media screen and (max-width: 1024px){
  .ceo-left h1{
    margin-left: 100px;
  }
  .ceo-name{
    margin-left: 100px;
    margin-top: 30px;
  }
  .ceo-qoute{
    margin-left: 100px;
  }
  .ceo-image {
    width: 450px;
    height: 500px;
  }
  .image-wrapper {
    margin-right: 0;
  }
}
@media screen and (max-width: 768px) {
  .wrap {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .content {
    padding: 10px;
    text-align: center;
  }

  .main-image {
    width: 100%;
    margin-top: 20px;
  }

  #wlcm-top, #wlcm-btm {
    font-size: 28px;
    margin: 10px 0;
  }

  .wlcm-saytum {
    font-size: 22px;
    margin-top: 10px;
  }

  .newlist {
    font-size: 16px;
    padding: 10px 0;
  }

  .extra {
    font-size: 18px;
    margin-top: 10px;
  }

  .btns {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .button-17 {
    width: 100%;
    padding: 10px;
  }

  .about-ceo {
    flex-direction: column;
    padding: 20px;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-bottom: 40px;
  }

  .ceo-left {
    text-align: start;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .ceo-left h1 {
    font-size: 40px;
    padding: 0 0 20px 0;
    margin: 0;
  }

  .ceo-name {
    font-size: 24px;
    margin-left: 0;
    margin-top: 30px;
  }

  .ceo-qoute, .ceo-details {
    /* font-size: 16px; */
    margin-left: 0;
  }


  .ceo-content{
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  .ceo-text{
    margin: 0;
  }

  .ceo-image {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .image-wrapper {
      height: 300px;
      width: 270px;
      overflow: hidden;
      border-radius: 10px;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
      /* background: #ddd; */
      background: transparent;
      margin-top: 0;
  }

  /* .ceo-image {
    width: 100%;
    height: 20%;
    visibility: hidden;
  } */

  .abtus {
    padding: 10px;
  }

  .about-container {
    display: flex;
    flex-direction: column !important;
    padding: 10px;
    box-shadow: none;
  }

  .abtleft, .abtright {
    padding: 10px;
  }

  .abt-img {
    width: 100%;
    height: auto;
  }

  .abtright{
    align-items: center;
  }

  .abtright h1 {
    font-size: 36px;
    text-align: center;
  }

  .abtright p {
    font-size: 18px;
    text-align: center;
  }

  /* Insights */
  .wrapper {
    padding: 10px;
  }

  .ins-head {
    font-size: 36px;
    margin-top: 10px;
  }

  .insights-box {
    padding: 10px;
    box-shadow: none;
  }

  .toggle-container {
    margin-left: 10px;
  }

  .insights-content {
    flex-direction: column-reverse;
    padding: 10px;
  }

  .insights-left{
    padding-top: 40px;
  }

  .insights-right {
    padding: 10px;
  }

  .ins-head1, .ins-head2, .ins-head3, .ins-head4 {
    font-size: 24px;
    /* text-align: center; */
  }

  .ins-desc1, .ins-desc2, .ins-desc3, .ins-desc4 {
    font-size: 16px;
    text-align: center;
  }

  .ins-list {
    margin-top: 20px;
  }

  .tabcontent img {
    max-width: 100%;
    height: auto;
    margin-top: 70px;
  }

  .ins-tabs .tablinks{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  /* .ins-tabs .tablinks::before {
    content: '';
    position: absolute;
    left: 41%;
    top: 0;
    width: 20%;
    height: 3px;
    background-color: #225678;
    transition: width 0.3s, left 0.3s;
  }
  
  .ins-tabs .tablinks.active::before,
  .ins-tabs .tablinks:hover::before {
    left: 0;
    width: 100%;
    height: 3px;
  } */

  /* Video Section */
  .vdo-container {
    padding: 20px;
  }

  .vdo-row {
    flex-direction: column;
    padding: 20px;
  }

  .vdo-main, .vdo-side {
    width: 100%;
    margin: 10px 0;
  }

  .video-wrapper {
    max-width: 100%;
  }

  .vdo-thumb2-wrapper {
    display: block;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
  }

  .vdo-thumb2 {
    position: absolute;
    width: 100%;
    height: 100%;
  }


  .vdo-thumb1-wrapper {
    display: block;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
  }

  .play-button {
      width: 40px;
      padding-top: 50%;
      position: relative;
      margin-left: 50%;
      transform: translate(-50%, -50%);
  }

  .vdo-smallrow {
    align-items: center;
  }

  .vdo-sidetxt, .vdo-maintxt {
    margin: 10px 0;
    font-size: 15px;
    text-align: center;
  }

  .misandvis {
    padding: 20px;
  }

  .mission-header h1 {
    font-size: 36px;
  }

  .mission-header p {
    font-size: 18px;
    padding: 10px;
  }

  .flex-container {
    flex-direction: column;
    align-items: center;
  }

  .ndflex {
    width: 100%;
    margin: 10px 0;
  }

  .miss-head {
    font-size: 22px;
  }

  .miss-content {
    font-size: 16px;
  }
}


@media screen and (max-width: 480px) {
  .tabcontent img {
    max-width: 100%;
    height: 250px;
    margin-top: 70px;
  }
}


@media screen and (max-width: 375px) {
  .tabcontent img {
    max-width: 100%;
    height: 250px;
    margin-top: 70px;
  }
}