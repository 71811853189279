@import "styles/_styles";

.dropdown {
    @include flex-row(flex-start, center);

    &__label {
        color: $color-dark-grey;
        font-size: 15px !important;
        font-weight: 500;
        font-family: $font-family !important;
        margin-right: 10px;
    }

    &__select {
        &__control {
            padding-top: 2px !important;
            border-color: transparent !important;
            min-width: 180px;

            &--is-focused {
                border-color: transparent !important;
                outline: none !important;
                box-shadow: none !important;
            }
        }
        &__placeholder {
            font-size: 14px !important;
            font-family: $font-family;
        }
        &__single-value {
            font-size: 14px !important;
            font-family: $font-family;
            font-weight: 500;
        }
        &__option {
            font-size: 14px !important;
            font-family: $font-family;

            &--is-selected {
                background-color: $color-primary !important;
            }
        }
        &__indicator {
            &-separator {
                display: none;
            }

            &.dropdown__select__dropdown-indicator {
                &::after {
                    margin-top: -4px;
                    content: url("../../images/icon/select-dropdown.png")
                }
                svg {
                    display: none;
                }
            }
        }
        &__value-container {
            padding: 0 !important;
        }
        &__menu {
            margin: 0 !important;
            &-list {
                padding: 0 !important;
            }
        }
    }
}