@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Roboto:wght@400;500&display=swap');

.ndnavbar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    background: rgba(255, 255, 255, 1);
    padding: 15px 20px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    transition: background 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 1px 2px rgba(255,255,255, 0.9);
}

.ndnavbar.active {
    background: rgba(34,86,120, 1);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.8);
}

input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none;
        -moz-appearance: textfield;
        appearance: none;
        margin: 0; 
    }

.nav-logo {
    width: 130px;
    height: auto;
    cursor: pointer;
    margin-left: 20px;
}


.nav-user {
    width: 45px;
    height: auto;
    cursor: pointer;
}

.nav-btns {
    flex: 1;
    list-style: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    padding: 0;
}

.nav-items {
    margin: 0 15px;
    position: relative;
}

.nav-links {
    font-size: 18px;
    color: rgb(54, 51, 51);
    /* color: white; */
    text-decoration: none;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    transition: color 0.3s ease, transform 0.2s ease-in-out;
}

.ndnavbar.active .nav-links {
    color: white;
}

.ndnavbar.active .nav-links:hover {
    color: #00A4BD;
    transform: translateY(-2px);
}

.nav-links:hover {
    color: #00A4BD;
    transform: translateY(-2px);
}

.nav-items::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #00A4BD;
    transition: width 0.3s ease;
    position: absolute;
    bottom: -5px;
    left: 0;
}

.nav-items:hover::after {
    width: 100%;
}

.navbar-track {
    background: linear-gradient(45deg, #00A4BD, #007E9E);
    border-radius: 4px;
    border: none;
    color: white;
    font-size: 15px;
    padding: 8px 20px;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    letter-spacing: 1px;
    transition: background 0.3s ease, transform 0.2s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar-track:hover {
    background: linear-gradient(45deg, #007E9E, #005D70);
    transform: translateY(-2px);
}



/* Dropdown Box */


.track-container {
    position: relative;
}

.newdropdown {
    position: absolute;
    top: 50px;
    right: 0;
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 15px;
    display: flex;
    flex-direction: column;
    z-index: 1001;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.track-container .newdropdown {
    opacity: 1;
    transform: translateY(0);
}

.newdropdown-input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
    font-size: 14px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.newdropdown-input:focus {
    border-color: #00A4BD;
    box-shadow: 0 0 5px rgba(0, 164, 189, 0.5);
    outline: none;
}

.newdropdown-btn {
    background: linear-gradient(45deg, #00A4BD, #007E9E);
    border: none;
    color: white;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    transition: background 0.3s ease, transform 0.2s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.newdropdown-btn:hover {
    background: linear-gradient(45deg, #007E9E, #005D70);
    transform: translateY(-2px);
}

.newdropdown-btn:active {
    background: linear-gradient(45deg, #005D70, #003E4E);
}

.newdropdown::before {
    content: '';
    position: absolute;
    top: -10px;
    right: 20px;
    border-width: 0 10px 10px 10px;
    border-style: solid;
    border-color: transparent transparent white transparent;
}

.navbar-track:focus + .newdropdown {
    opacity: 1;
    transform: translateY(0);
}

/* Responsive CSS */
@media screen and (max-width: 768px) {
    .ndnavbar {
      padding: 10px 15px;
    }
  
    .nav-logo {
      width: 100px;
      margin-left: 10px;
    }
  
    .nav-user {
      width: 35px;
    }
  
    .nav-btns {
      flex-direction: row;
      align-items: flex-end;
      gap: 10px;
    }
  
    .nav-items {
      margin: 10px 0;
    }
  
    .nav-links {
      font-size: 16px;
    }
  
    .navbar-track {
      font-size: 10px;
      padding: 5px 10px;
      margin-left: 10px;
    }
  
    .newdropdown {
      top: 40px;
      width: 150px; /* Adjust width */
      /* padding: 20px; Adjust padding */
      right: 5%;
  }

  .newdropdown-input {
      font-size: 12px;
      width: 100%;
      padding: 8px;
  }

  .newdropdown-btn {
      font-size: 12px;
      padding: 8px;
      width: 100%;
  }

  .newdropdown::before {
      right: 50%;
      transform: translateX(50%);
  }

  .ndnavbar.active .nav-links {
    color: rgb(9, 9, 9);
  }
  }
  
  @media screen and (max-width: 375px) {
    .ndnavbar {
      padding: 8px 10px;
    }
  
    .nav-logo {
      width: 80px;
      margin-left: 5px;
    }
  
    .nav-user {
      width: 30px;
    }
  
    .nav-links {
      font-size: 14px;
    }
  
    .navbar-track {
      font-size: 10px;
      padding: 5px 10px;
      margin-left: 10px;
    }
  
    .newdropdown {
      top: 40px;
      width: 150px; /* Adjust width */
      /* padding: 20px; Adjust padding */
      right: 5%;
  }

  .newdropdown-input {
      font-size: 12px;
      width: 100%;
      padding: 8px;
  }

  .newdropdown-btn {
      font-size: 12px;
      padding: 8px;
      width: 100%;
  }

  .newdropdown::before {
      right: 50%;
      transform: translateX(50%);
  }
  }

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  margin-right: 20px;
}

.hamburger div {
  width: 25px;
  height: 2px;
  background-color: #333;
  margin: 4px 0;
  transition: 0.4s;
}

.nav-btn {
  display: flex;
}



@media screen and (max-width: 768px) {
  .nav-btn {
    display: none;
    flex-direction: column;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 60px;
    left: 0;
    background-color: rgba(255, 255, 255, 1);
    /* gap: 15px; */
  }

  .nav-btn.active {
    display: flex;
  }

  .hamburger {
    display: flex;
  }

  .ndnavbar.active .nav-links {
    color: rgb(9, 9, 9);
  }
}

/* Optional: Hamburger animation */
.hamburger.toggle .bar1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.hamburger.toggle .bar2 {
  opacity: 0;
}

.hamburger.toggle .bar3 {
  transform: rotate(45deg) translate(-5px, -6px);
}


