@import "styles/_styles";

.input {
    margin: 10px;
    position: relative;

    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none;
        -moz-appearance: textfield;
        appearance: none;
        margin: 0; 
    }
    &__label {
        display: block;
        margin: 25px 0 5px 0;
        font-family: $base-font;
        font-size: 14px;
        color: $color-dark;

        @include respond-below(md) {
            font-size: 12px;
        }

        &__error {
            color: $color-danger;
        }
    }

    &__required{
        content: "*";
        color: red;
        font-weight: bold;
    }

    &__control {
        width: 100%;
        border-radius: 8px;
        border: 1px solid $color-light-grey;
        padding: 15px;
        font-family: $base-font;
        font-size: 15px;
        color: $color-dark;

        @include respond-below(md) {
            padding: 12px;
            font-size: 14px;
        }

        &:read-only {
            background-color: #f2f2f2;
        }

        &__area {
            min-height: 120px;

            @include respond-below(md) {
                min-height: 100px;
            }
        }

        &__error {
            border: 1px solid $color-danger;
            color: $color-danger;

            &::placeholder {
                color: $color-danger;
            }

            &:focus {
                outline: none;
                border: 1px solid $color-danger !important;
            }
        }
 
        &:focus {
            outline: none;
            border: 1px solid $color-border-input;
        }

        &__password {
            font: small-caption;
            font-size: 16px;

            @include respond-below(md) {
                font-size: 14px;
            }
        }
    }

    &__caption {
        font-size: 13px;
        margin-top: 5px;
        color: $color-orange;
        font-weight: bold;

        @include respond-below(md) {
            font-size: 11px;
        }

        &__error {
            color: $color-danger;
        }
    }

    &__icon {
        position: absolute;
        width: 25px;
        height: 25px;
        z-index: 9;

        @include respond-below(md) {
            width: 22px;
            height: 22px;
        }

        &__label {
            font-size: 12px;
            color: $color-dark;
            margin: 0;

            @include respond-below(md) {
                font-size: 10px;
            }
        }

        &__button {
            background-color: transparent;
            border: none;
            margin-top: 12px !important;

            @include respond-below(md) {
                margin-top: 8px !important;
            }

            &__suffix {
                right: 20px !important;
            }

            &__prefix {
                left: 10px !important;
            }

            img {
                width: 25px;
                height: 25px;

                @include respond-below(md) {
                    width: 22px;
                    height: 22px;
                }
            }
        }

        &__prefix {
            left: 15px;
            margin-top: 14px;

            @include respond-below(md) {
                margin-top: 12px;
            }
            
            ~ .input {
                &__control {
                    padding-left: 48px;

                    @include respond-below(md) {
                        padding-left: 45px;
                    }
                }

                &__select {
                    &__control {
                        padding-left: 48px;

                        @include respond-below(md) {
                            padding-left: 45px;
                        }
                    }
                }
            }
        }

        &__suffix {
            right: 15px;
            margin-top: 14px;
            
            @include respond-below(md) {
                margin-top: 12px;
            }
            
            ~ .input {
                &__control {
                    padding-right: 50px;

                    @include respond-below(md) {
                        padding-right: 48px;
                    }
                }
            }
        }
    }

    &__phone {
        @include flex-row(center, center);

        &__control {
            width: calc(100% - 80px);
            border-left: none;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            margin: 0;

            &:read-only {
                background-color: #f2f2f2;
            }
        }
    }
}