@import "styles/_styles";

.loader {

    @include flex-row(center, center);

    &__overlay {
        width: 100%;
        height: 100%;
        position: fixed;
        background-color: rgba(0,0,0,0.7);
        z-index: 9999;
        top: 0;
    }

    &__container {
        position: fixed;
        width: 600px;
        background-color: $color-light;
        z-index: 99999;
        padding: 40px 0;
        border-radius: 10px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        top: 10em;
        @include flex-column(center, center);

        @include respond-below(md) {
            width: 90%;
            padding: 20px 20px;
            text-align: center;
        }
    }

    &__header {
        font-weight: bold;
        font-size: 28px;
        font-family: $font-family !important;

        @include respond-below(md) {
            font-size: 18px;
        }
    }

    &__description {
        font-size: 16px !important;
        font-family: $font-family !important;
        margin: 0;

        @include respond-below(md) {
            font-size: 12px !important;
        }
    }

    &__content {
        display: inline-block;
        position: relative;
        width: 65px;
        height: 65px;
        margin-bottom: 30px;

        @include respond-below(md) {
            width: 40px;
            height: 40px;
            margin-left: -30px;
            margin-bottom: 20px;
        }

        div {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: 64px;
            height: 64px;
            margin: 8px;
            border: 8px solid $color-success;
            border-radius: 50%;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: $color-success transparent transparent transparent;

            @include respond-below(md) {
                width: 40px;
                height: 40px;
                border-width: 6px;
            }

            &:nth-child(1) {
                animation-delay: -0.45s;
            }
            &:nth-child(2) {
                animation-delay: -0.3s;
            }
            &:nth-child(3) {
                animation-delay: -0.15s;
            }
        }

        @keyframes lds-ring {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
        }
    }

    &__contents {
        text-align: center;
        margin: 30px 0 50px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}
  
