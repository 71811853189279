@import "styles/_styles.scss";

.details{
    margin-left: 10%;
    margin-right: 10%;

    &__header{
        font-size: 35px;
        font-weight: 700;
        color: #2E2E2E;
        line-height: 140%;
        /* margin-bottom: 56px; */
        text-align: center
    }
}

.top {
    // padding-top: 40px;
    // padding-left: 20% ;
    // padding-right: 20% ;
    // margin-left: 9% !important;
}

/*Icon progressbar*/
#progressbar {
    margin-bottom: 30px;
    overflow: hidden;
    color: #455A64;
    padding-left: 0px;
    margin-top: 30px;
} 

#progressbar li {
    list-style-type: none;
    font-size: 13px;
    width: 25%;
    float: left;
    position: relative;
    font-weight: 400;
}

#progressbar .step0:before {
    font-family: FontAwesome;
    content: "\f10c";
    color: #fff;
}

#progressbar li:before {
    width: 40px;
    height: 40px;
    line-height: 45px;
    display: block;
    font-size: 20px;
    background: #C5CAE9;
    border-radius: 50%;
    margin: auto;
    padding: 0px;
}

/*ProgressBar connectors*/
#progressbar li:after {
    content: '';
    width: 100%;
    height: 12px;
    background-color: #C5CAE9;
    position: absolute;
    left: 0;
    top: 16px;
    z-index: -1;
}

#progressbar li:last-child:after {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    position: absolute;
    left: -50%;
}

#progressbar li:nth-child(2):after, #progressbar li:nth-child(3):after {
    left: -50%;
}

#progressbar li:first-child:after {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    left: 50%;
}

#progressbar li:last-child:after {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

#progressbar li:first-child:after {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

/*Color number of the step and the connector before it*/
#progressbar li.active:before, #progressbar li.active:after {
    background: #651FFF;
}

#progressbar li.active:before {
    font-family: FontAwesome;
    content: "\f00c";
}

.icon {
    width: 80px !important;
    height: 70px;
    margin-right: 15px;
}

.icon-content { 
    padding-bottom: 20px;
    padding-left: 165px;
}

@media screen and (max-width: 600px) {
    .icon-content {
        width: 10% !important;
        font-size: small;
        margin-right: 7px;
        padding-left: 0%;
    }
    .ticks{
        --bs-gutter-x: 0 !important;
        width: 375px;
    }
    .top{
        margin-left: 5% !important;
    }
    .icon {
        display: none;
        visibility: hidden;
    }
    .nav {
    width: 100% !important;
    display: contents !important;
    }
    .card{
        --bs-card-spacer-x: 0px !important;
        --bs-card-spacer-y: 0px !important;
    }
    .table{
        width: 100%;
        margin-bottom: 0px !important;
    }

//   .card-body{
//     padding: 0% !important;
//   }
}