@import "styles/_styles";

.input {

    &__autocomplete {
        border: none !important;
        position: relative;
        &__suggestion {
            position: absolute;
            width: 100%;
            z-index: 1000;
            outline: none !important;
            box-shadow: none !important;
            background-color: white;
            border-radius: 8px;

            &__item {
                border-right: 1px solid $color-form-control !important;
                border-left: 1px solid $color-form-control !important;
                padding: 5px 10px;    
    
                &:hover {
                    background-color: $border-color;
                }
    
                &:first-child {
                    padding-top: 10px;
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                    border-top: 1px solid $color-form-control !important;
                }
                &:last-child {
                    padding-bottom: 10px;
                    border-bottom-left-radius: 8px;
                    border-bottom-right-radius: 8px;
                    border-bottom: 1px solid $color-form-control !important;
                }
    
                &__text {
                    cursor: pointer;
                    font-size: 14px;
                    font-family: $font-family;
                }
            }
        }
    }

}