.detail {

    &__modal {

        &__container {
            margin: 0 !important;
        }

    }

}