@import url('https://fonts.googleapis.com/css2?family=Hammersmith+One&family=Playfair+Display:wght@400;700&family=Poppins:wght@400;500;600&display=swap');
@import "styles/_styles";

.pp-header {
    background: url('../../common/images/PP-Banner.png') no-repeat center center;
    background-size:cover;
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* margin-top: 40px; */

}
.pp-header h1{
    color: white;
    font-weight: 500;
    font-family: "Hammersmith One", sans-serif;
    font-size: 60px;
    margin-left: 100px;
}

.pp-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    background: linear-gradient(to right, #d8e5f3, #bfd5ef);
    /* padding: 50px 0; */
    padding-bottom: 50px;
    animation: fadeIn 1.5s ease-in-out;
}

.pp-content {
    background: white;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    width: 90%;
    transform: translateY(30px);
    opacity: 0;
    margin-top: 40px;
    animation: slideUpFadeIn 1s forwards;
}

.pp-content h3 {
    color: #225678;
    margin-bottom: 15px;
    font-size: 30px;
    font-weight: 500;
    font-family: "Hammersmith One", sans-serif;
}

.pp-content p {
    color: #333;
    margin-bottom: 15px;
    line-height: 1.6;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

.pp-content ul {
    padding-left: 40px;
    margin-bottom: 15px;
}

.pp-content li {
    margin-bottom: 10px;
    font-weight: bold;
    font-family: 'Poppins', sans-serif; 
}

.pp-content li:before {
    content: "•";
    color: #225678;
    font-weight: bold;
    display: inline-block; 
    width: 1em;
    margin-left: -1em;
}

.pp-content .section-divider {
    margin: 30px 0;
    border-top: 2px solid #e0e0e0;
}

.pp-content a {
    color: #225678;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease-in-out;
}

.pp-content a:hover {
    text-decoration: underline;
    color: #183d5d;
}

/* Keyframe animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideUpFadeIn {
    from {
        transform: translateY(30px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.pp-content h3:hover,
.pp-content p:hover,
.pp-content ul:hover,
.pp-content li:hover,
.pp-content li:before:hover {
    color: #183d5d;
}

/* Responsive Styles */
@media (max-width: 768px) {

    .pp-header {
        height: 200px;
        justify-content: center;
        margin-top: -34px;
    }

    .pp-header h1 {
        color: black;
        font-size: 40px;
        margin-left: 20px;
    }

    .pp-content {
        width: 95%;
        padding: 20px;
    }

    .pp-content h3 {
        font-size: 24px;
    }

    .pp-content p {
        font-size: 14px;
    }

    .pp-content ul {
        padding-left: 20px;
    }

    .pp-content li {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .pp-header {
        height: 65px;
    }

    .pp-header h1 {
        font-size: 30px;
        margin-left: 10px;
    }

    .pp-container{
        padding-bottom: 0px;
    }

    .pp-content {
        width: 100%;
        padding: 15px;
        margin-top: 0px;
    }

    .pp-content h3 {
        font-size: 20px;
    }

    .pp-content p {
        font-size: 12px;
    }

    .pp-content ul {
        padding-left: 15px;
    }

    .pp-content li {
        font-size: 12px;
    }
}