@import "styles/_styles";

.worker-card {
    padding: 20px;
    margin: 0px 0 20px 20px;

    @include respond-below(md) {
        margin: 0;
        padding: 0;
    }

    &__item {
        border: 1px solid $color-light-grey;
        @include flex-row(space-between, center);
        padding: 10px;
        border-radius: 4px;
        margin-bottom: 15px;

        &__image {
            width: 45px;
            margin-right: 10px;
            height: 45px;
            border-radius: 50%;
            border: 1px solid $color-light-grey;
        }

        &__header {
            margin: 0;
            font-size: 16px;
            font-weight: 600;
            text-transform: capitalize;

            @include respond-below(md) {
                font-size: 14px;
                margin-bottom: 5px;
            }
        }

        &__description {
            margin: 0;
            font-size: 14px;

            @include respond-below(md) {
                font-size: 14px;
            }
        }

        &__arrow {
            margin-right: 15px;
            width: 10px;
        }

        &__wrapper {
            max-width: 85%;
        }
    }
}