@import url('https://fonts.googleapis.com/css2?family=Hammersmith+One&family=Playfair+Display:wght@400;700&family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Roboto:wght@400;500&display=swap');

.boxbg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 100%;
    backdrop-filter: blur(8px);
}

.log-box {
    position: absolute;
    z-index: 100;
    top: 50%;
    left: 50%;
    background: linear-gradient(145deg, #f5f7fa, #c3cfe2);
    transform: translate(-50%, -50%);
    width: 360px;
    height: 340px;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
    text-align: center;
    display: flex;
    flex-direction: column;
    /* transition: transform 0.0s ease-in-out;
    animation: fadeIn 0.5s ease; */
}

.log-title {
    font-size: 28px;
    color: #333;
    font-family: 'Hammersmith One', sans-serif;
    font-weight: 500;
    margin-bottom: 30px;
}

.log-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 150px;
}

.sign-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 150px;
    margin-top: 30px;
}

.log-button {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Align items to the left */
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    padding: 12px 30px;
    border: none;
    border-radius: 10px;
    height: 50px;
    width: 100%;
    max-width: 240px;
    background: linear-gradient(45deg, #00A4BD, #007E9E);
    color: white;
    font-size: 18px;
    cursor: pointer;
    transition: background 0.3s, transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
}

.log-button:hover {
    background: linear-gradient(45deg, #007E9E, #00A4BD);
    transform: scale(1.05);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.faicon-logbox {
    margin-right: 30px;  /* Adjust the margin as needed */
}

.btn-txt1, .btn-txt2, .btn-txt3 {
    text-align: left; /* Align text to the left */
}

.btn-txt1{
    margin-left: 5px;
}

.cross-button {
    color: #555;
    position: absolute;
    right: 20px;
    top: 15px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.cross-button:hover {
    color: red;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translate(-50%, -40%);
    }
    to {
        opacity: 1;
        transform: translate(-50%, -50%);
    }
}

/* Responsive CSS */
@media screen and (max-width: 768px) {
    .log-box {
        width: 90%;
        height: auto;
        padding: 30px;
    }

    .log-title {
        font-size: 24px;
        margin-bottom: 20px;
    }

    .log-button {
        font-size: 16px;
        padding: 10px 25px;
    }

    .cross-button {
        font-size: 1.2rem;
        right: 15px;
        top: 10px;
    }
    
    .faicon-logbox {
        margin-left: 10px;
        margin-right: 40px;  /* Adjust the margin as needed */
    }
}

@media screen and (max-width: 480px) {
    .log-box {
        width: 90%;
        height: auto;
        padding: 20px;
    }

    .log-title {
        font-size: 20px;
        margin-bottom: 15px;
    }

    .log-button {
        font-size: 14px;
        padding: 8px 20px;
    }

    .cross-button {
        font-size: 1rem;
        right: 10px;
        top: 5px;
    }
    .faicon-logbox {
        margin-left: 10px;
        margin-right: 50px;
    }
}

@media screen and (max-width: 375px) {
    .log-box {
        width: 95%;
        height: auto;
        padding: 15px;
    }

    .log-title {
        font-size: 18px;
        margin-bottom: 10px;
    }

    .log-button {
        font-size: 12px;
        padding: 6px 15px;
    }

    .cross-button {
        font-size: 0.9rem;
        right: 5px;
        top: 5px;
    }
    .faicon-logbox {
        margin-left: 30px;
        margin-right: 45px;
    }
}
