@import "styles/_styles.scss";

.card {
    z-index: 0;
    // background-color: #ECEFF1;
    // padding-bottom: 20px;
    margin-top: 50px;
    margin-bottom: 90px;
    --bs-card-cap-padding-x:0px !important;
    border: 1px solid rgba(0,0,0,0.175);
    border-radius: 10px;
}
