@import "styles/_styles";

.input {

    &__file {
        @include flex-row(space-between, center);
        border: 1px solid $color-light-grey;
        border-radius: 8px;
        padding: 5px 5px 5px 12px;

        &__name {
            font-size: 15px;
            color: $color-dark;
            font-family: $font-family;
            max-width: 70%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            @include respond-below(md) {
                max-width: 60%;
                font-size: 14px;
            }
        }

        &__btn {
            background-color: $upload-btn-color;
            border: none;
            outline: none;
            padding: 10px 15px;
            border-radius: 8px;
            color: $color-light;
            font-family: $font-family;
            font-size: 13px;
            font-weight: 600;
            text-transform: uppercase;
            line-height: 22px;
            max-width: 30%;
            overflow: hidden;

            &:focus, &:hover {
                border: none;
                outline: none;
                box-shadow: none;
            }

            @include respond-below(md) {
                max-width: 40%;
                font-size: 12px;
                padding: 10px 15px;
            }
        }
    }

}