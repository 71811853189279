@import "styles/_styles.scss";

.navbar {
    background: $color-primary-gradient;
    padding: 0 45px !important;
    margin: 0 !important;
    position: relative;
    z-index: 1000;

    @include flex-row(space-between, center);

    @include respond-below(md) {
        min-height: 100px;
        padding: 0 10px !important;
        width: 100%;
        justify-content: center;
    }

    &__left {
        @include flex-row(flex-start, center);
    }

    &__group {
        @include flex-row(flex-start, center);
    }

    &__logo {
        width: 74px;
        height: 36px;
        
        @include respond-below(md) {
            width: 50px;
            height: 30px;
        }
    }

    &__menu {
        list-style: none;
        margin: 0;

         @include respond-below(md) {
            display: none;
         }

        li {
            display: inline-block;
            padding: 35px 25px;

            &.active {
                a {
                    font-weight: $font-bold;
                    width: auto;
                    position: relative;

                    // &::after {
                    //     content: "";
                    //     display: block;
                    //     background-color: $color-light;
                    //     width: 100%;
                    //     position: absolute;
                    //     height: 7px;
                    //     bottom: -2.2em;
                    //     border-top-left-radius: 10px;
                    //     border-top-right-radius: 10px;
                    // }
                }
            }

            a {
                margin-bottom: 0;
                color: $color-light !important;
                font-family: $font-family;
                font-size: 16px;
                text-decoration: none;
                text-transform: capitalize;
                background-color: transparent;
                border: none;
                cursor: pointer;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    &__profile {
        @include flex-row(flex-start, center);

        &__image {
            width: 45px;
            height: 45px;
            background-color: $color-light;
            border-radius: 50%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            background-origin: content-box;
            margin-right: 12px;

            &.centered {
                @include flex-row(center, center);
            }

            img {
                width: 24px;
            }

            &.empty {
                background-color: $color-grey;
                @include flex-column(center, center);
            }

            &__text {
                margin-bottom: 0;
                color: $color-light;
                font-size: 18px !important;
                font-family: $font-family;
                font-weight: 700;
                text-transform: uppercase;
            }
        }

        &__content {
            margin-right: 30px;
            text-align: left;
            &__username {
                color: $color-light;
                font-size: 15px;
                font-weight: $font-bold;
                text-transform: uppercase;
                font-family: $font-family;
                margin: 0;
                margin-bottom: 3px;
            }
            &__title {
                color: $color-light;
                font-size: 14px;
                font-weight: $font-normal;
                text-transform: capitalize;
                font-family: $font-family;
                margin: 0;
            }
        }

        &__arrow {
            img {
                width: 12px;
                height: 12px;
            }
        }
    }

    &__switcher {
        @include flex-row(flex-start, center);

        &__icon {
            margin-right: 8px;
            margin-top: -2px;

            @include respond-below(md) {
                width: 14px;
            }
        }

        &__title {
            margin: 0;
            font-family: $font-family;
            font-weight: $font-normal;
            font-size: 12px;

            @include respond-below(md) {
                font-size: 10px;
            }
        }

        &::after {
            content: url('../../images/icon/arrow-down-lg.svg');
            margin-top: 10px;
            margin-left: 10px;

            @include respond-below(md) {
                content: url('../../images/icon/arrow-down.svg');
                margin-top: -3px;
            }
        }

        &__btn {
            padding: 0 !important;
            margin: 0 10px !important;
            border: none !important;
            border-radius: 8px !important;

            &:focus {
                outline: none !important;
                box-shadow: none !important;
            }

            &.btn-primary {
                background-color: $color-light !important;
                border: none !important;
                padding: 0 !important;
                
                &:hover {
                    background-color: $color-light !important;
                }
            }

            &.btn {
                &__outline {
                    background-color: transparent !important;

                    &:hover {
                        background-color: transparent !important;
                    }
                }
            }

            &::after {
                display: none !important;
            }

            &__container {
                padding: 10px 15px;
                // background-color: $color-light;
                border-radius: 10px;
                border: 1px solid $color-light;

                &__title {
                    font-size: 14px;
                    font-family: $font-family;
                    font-weight: $font-bold;
                    margin: 0;
                    text-transform: capitalize;
                    color: $color-primary;
                }
        
                &__outline {
                    background-color: transparent !important;
        
                    &__title {
                        color: $color-light !important;
                    }
                }
            }
        }
    }

    &__overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        background-color: $color-overlay;
        z-index: -1;
    }
}