@import "styles/_styles";

.input {
    &__checkbox {
        display: block;
        position: relative;
        padding-left: 35px;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        height: 54px;
        padding-right: 20px;

        @include flex-row(flex-start, center);

        &__wrapper {
            background-color: $color-form-filled;
            margin: 10px;
            border-radius: 8px;
        }

        &__control {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            display: block;
            height: 30px;
            width: 30px;
            top: 12px;
            left: 12px;
            z-index: 1;

            &:checked {
                &~.input__checkbox__custom-control {
                    background-color: $color-success;

                    &::before {
                        content: url('../../images/icon/checklist.svg');
                        position: absolute;
                        top: -1.5px;
                    }
                }
            }
        }

        &__custom-control {
            display: flex;
            flex-direction: row;
            justify-content: center;
            position: absolute;
            top: 12px;
            left: 12px;
            height: 30px;
            width: 30px;
            background-color: $color-light-grey;
            border-radius: 5px;
        }

        &__label {
            font-size: 12px;
            color: $color-dark;
            font-family: $font-family;
            font-weight: $font-semi-bold;
            margin: 0;
        }

        &__information {
            font-size: 10px;
            color: $color-checkbox-label-description;
            font-family: $font-family;
            font-weight: $font-semi-bold;
            margin: 0;
        }
    }
}