@import "styles/_styles";

.table {
    width: 100%;
    margin-bottom: 10px !important;

    @include respond-below(md) {
        width: 500px;
    }

    &__wrapper {
        border: 1px solid $table-border-color;
        border-radius: 6px;
        padding: 15px;

        @include respond-below(md) {
            overflow: auto;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    &__head {
        &__title {
            border-top: none !important;
            font-family: $font-family;
            font-size: 15px;
            font-weight: $font-bold;
            border: none !important;

            &.center {
                text-align: center !important;
            }

            @include respond-below(md) {
                font-size: 10px;
                vertical-align: inherit !important;
            }

            &:last-child {
                @include respond-below(md) {
                    background-color: $color-light;
                    position: sticky;
                    right: -18px;
                    box-shadow: 0 0 10px 0 rgba(0,0,0,0.4);
                }
            }
        }
    }

    &__body {

        &__row {
            &__odd {
                background-color: #f2f2f2;
            }

            &__column {
                padding: 8px 0.75em !important;
                font-family: $font-family;
                font-size: 14px;
                font-weight: $font-normal;
                border: none !important;

                @include respond-below(md) {
                    overflow: visible !important;
                    font-size: 12px;
                    vertical-align: inherit !important;
                }

                &:last-child {
                    @include respond-below(md) {
                        background-color: $color-light;
                        position: sticky;
                        right: -18px;
                    }
                }
                
                &.center {
                    text-align: center !important;
                }

                &__text-style {
                    padding: 7px 0;
                }

                &__btn-style {
                    padding: 10px 5px;
                    text-align: center;
                    border-radius: 8px;
                    font-size: 12px;
                    font-weight: $font-bold;
                    font-family: $font-family;
                    max-width: 120px;
                    text-transform: uppercase;

                    &__success {
                        background-color: $color-light-success;
                        color: $color-success;
                    }

                    &__primary {
                        background-color: $color-light-primary;
                        color: $color-primary;
                    }

                    &__default {
                        background-color: $color-default;
                        color: $color-dark;
                    }

                    &__danger {
                        background-color: $color-light-danger;
                        color: $color-danger;
                    }
                }

                &__dropdown {
                    text-align: center !important;
                    &.center {
                        text-align: center !important;
                    }

                    &.dropdown {
                        justify-content: center;
                    }

                    button {
                        height: 0 !important;
                        background: transparent !important;
                        text-align: right;
                        max-width: 50px;
                        margin: 0 ;
                        border: none !important;
                        margin-top: -0.5em;
                        padding: 0 !important;

                        .material-icons {
                            color: $color-dark;
                        }

                        &::after {
                            height: 0 !important;
                            margin: 0 !important;
                            padding: 0 !important;
                            display: none !important;
                        }
                    }

                    .dropdown-menu {
                        background-color: $color-dark;
                        padding: 0;
                        border-radius: 8px;

                        .dropdown-item {
                            display: flex;
                            color: $color-light;
                            font-family: $font-family;
                            font-weight: $font-normal;
                            font-size: 13px;
                            padding: 10px 20px 5px;

                            @include respond-below(md) {
                                padding: 10px 18px 5px !important;
                            }

                            &__detail {
                                padding-top: 20px;

                                &::before {
                                    content: url('../../images/icon/lists.svg');
                                    margin-right: 5px;
                                    margin-top: 0px;
                                }

                                &:hover {
                                    &::before {
                                        content: url('../../images/icon/lists-danger.svg');
                                    }
                                }
                            }

                            &__delete {
                                padding-bottom: 15px;

                                &::before {
                                    content: url('../../images/icon/trash.svg');
                                    margin-right: 5px;
                                    margin-top: 0px;
                                }

                                &:hover { 
                                    &::before {
                                        content: url('../../images/icon/trash-danger.svg');
                                    }
                                }
                            }

                            &__edit {

                                &::before {
                                    content: url('../../images/icon/writing.png');
                                    width: 16px;
                                    height: 16px;
                                    margin-right: 5px;
                                    margin-top: 0px;
                                }

                                &:hover { 
                                    &::before {
                                        content: url('../../images/icon/writing-danger.png');
                                    }
                                }
                            }

                            &__publish {

                                &::before {
                                    content: url('../../images/icon/publish.png');
                                    width: 16px;
                                    height: 16px;
                                    margin-right: 5px;
                                    margin-top: 0px;
                                }

                                &:hover { 
                                    &::before {
                                        content: url('../../images/icon/publish-danger.png');
                                    }
                                }
                            }

                            &__activate {

                                &::before {
                                    content: url('../../images/icon/activate.png');
                                    width: 16px;
                                    height: 16px;
                                    margin-right: 5px;
                                    margin-top: 0px;
                                }

                                &:hover { 
                                    &::before {
                                        content: url('../../images/icon/activate-danger.png');
                                    }
                                }
                            }

                            &__approve {

                                &::before {
                                    content: url('../../images/icon/approve.png');
                                    width: 16px;
                                    height: 16px;
                                    margin-right: 5px;
                                    margin-top: 0px;
                                }

                                &:hover { 
                                    &::before {
                                        content: url('../../images/icon/approve-danger.png');
                                    }
                                }
                            }

                            &__delivered {

                                &::before {
                                    content: url('../../images/icon/delivered.png');
                                    width: 16px;
                                    height: 16px;
                                    margin-right: 5px;
                                    margin-top: 0px;
                                }

                                &:hover { 
                                    &::before {
                                        content: url('../../images/icon/delivered-danger.png');
                                    }
                                }
                            }

                            &__update-location {

                                &::before {
                                    content: url('../../images/icon/update-location.png');
                                    width: 16px;
                                    height: 16px;
                                    margin-right: 5px;
                                    margin-top: 0px;
                                }

                                &:hover { 
                                    &::before {
                                        content: url('../../images/icon/update-location-danger.png');
                                    }
                                }
                            }

                            &:last-child {
                                padding-bottom: 15px;

                                @include respond-below(md) {
                                    padding-bottom: 12px !important;
                                }
                            }

                            &:first-child {
                                padding-top: 20px;
                                
                                @include respond-below(md) {
                                    padding-top: 15px !important;
                                }
                            }

                            &:hover {
                                color: $color-danger;
                                background-color: transparent;
                            }
                        }

                        @include respond-below(md) {
                            right: 40px !important;
                        }
                    }
                }

                &__small {
                    max-width: 50px !important;
                }
            }
        }
    }

    &__pagination {
        @include flex-row(flex-end, center);
        margin: 15px;
        margin-bottom: 20px;

        &__label {
            font-size: 14px;
            font-weight: $font-bold;
            text-transform: uppercase;
            margin-bottom: 0;
            margin-right: 10px;
        }

        &__paging {
            @include flex-row(center, center);
            button {
                background: transparent;
                border: 1px solid $color-light-grey !important;
                outline: none;
                margin: 0 5px;
                @include flex-column(center, center);
                height: 40px;
                width: 40px;
                border-radius: 8px;

                .material-icons {
                    font-size: 30px;
                }
            }
        }
    }
}