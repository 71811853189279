@import "styles/_styles";

.input {
    &__select {
        &__control {
            padding: 8px;
            border-radius: 8px !important;
            font-family: $base-font;

            @include respond-below(md) {
                padding: 4px;
            }

            &--is-focused {
                border-color: $color-form-control !important;
                box-shadow: 0 0 0 0 transparent !important;
            }
        }

        &__menu {
            margin: 0 !important;
            z-index: 9999 !important;

            &-list {
                padding: 0 !important;
                border-radius: 5px !important;
            }
        }

        &__option {
            font-family: $base-font !important;
            padding: 12px !important;
            font-size: 14px !important;
            text-transform: capitalize !important;

            &--is-selected {
                background-color: $color-primary !important;
            }
        }

        &__icon {
            &__prefix {
                &.input__select__phone {
                    width: 110px !important;
                }

                ~ .input__phone__control {
                    width: calc(100% - 110px) !important;
                }

                .input__select__control, .input__select__error__control {
                    padding-left: 38px !important;
                }
            }

            &__suffix {

                &.input__select__phone {
                    width: 110px !important;
                }

                ~ .input__phone__control {
                    width: calc(100% - 110px) !important;
                }

                .input__select__control, .input__select__error__control {
                    padding-right: 38px !important;
                }
            }
        }

        &__phone {
            width: 80px;

            .input__select__indicators, .input__select__error__indicators {
                display: none;
            }
            .input__select__control, .input__select__error__control {
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
            }
        }

        &__single-value {
            font-size: 15px !important;
            @include respond-below(md) {
                font-size: 13px;
            }
        }

        &__placeholder {
            font-size: 14px;

            @include respond-below(md) {
                font-size: 13px;
            }
        }

        &__error {
            &__control {
                font-family: $base-font;
                border-color: $color-danger !important;
                padding: 8px;
                border-radius: 8px !important;
                font-family: $base-font;

                @include respond-below(md) {
                    padding: 4px;
                }
    
                &--is-focused {
                    border-color: $color-danger !important;
                    box-shadow: 0 0 0 0 transparent !important;
                }
            }

            &__single-value {
                color: $color-danger !important;
            }

            &__placeholder {
                color: $color-danger !important;
            }

            &__menu {
                margin: 0 !important;
                z-index: 9999 !important;
    
                &-list {
                    padding: 0 !important;
                    border-radius: 5px !important;
                }
            }

            &__option {
                font-family: $base-font !important;
                padding: 12px !important;
                font-size: 14px !important;
                text-transform: capitalize !important;
    
                &--is-selected {
                    background-color: $color-primary !important;
                }
            }
        }
    }
}