.row {
    width: 100%;

    &__full {
        margin: 0 !important;
        padding: 0 !important;
    }

    &__md {
        @include respond-below(md) {
            &__full {
                width: 100% !important;
                margin: 0 !important;
                padding: 0 !important;
            }
        }
    }

    &__center {
        align-items: center !important;
    }
}

.col {

    &__6 {
        width: 48% !important;

        &:first-child {
            margin-right: 1% !important;
        }
        &:last-child {
            margin-left: 1% !important;
        }
    }

    &__full {
        margin: 0 !important;
        padding: 0 !important;
    }

    &__white {
        background-color: $color-light;
    }

    &__md {
        @include respond-below(md) {
            &__full {
                width: 100% !important;
                margin: 0 !important;
                padding: 0 !important;
            }
            &__margin-bottom {
                margin-bottom: 20px !important;
            }
        }
    }
}

.hidden-md {
    @include respond-below(md) {
        display: none !important;
    }
}

.hidden-lg {
    @include respond-above(md) {
        display: none !important;
    }
}

.md-no-margin {
    margin: 0 !important;
}