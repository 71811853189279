@import "styles/_styles";

.confirmation {

    z-index: 99999 !important;
    
    &__backdrop {
        z-index: 9999 !important;
    }

    .modal-dialog {
        
        @include respond-below(md) {
            padding: 10px;
        }

        .modal-content {
            min-width: 450px;
            border-radius: 20px;
            padding: 25px;

            @include respond-below(md) {
                min-width: 100%;
                padding: 15px;
            }

            .modal-body {
                padding: 0;
                @include flex-column(center, center);
            }
        }
    }

    &__body {
        &__closer {
            position: absolute;
            top: 0;
            right: 0;
            border: none;
            background: transparent;

            img {
                width: 18px;
            }

            @include respond-below(md) {
                top: 5px;
                right: 5px;
            }
        }

        &__icon {
            margin-top: 15px;
            width: 300px;
            margin-bottom: 30px;

            @include respond-below(md) {
                margin-bottom: 20px;
                display: none;
            }
        }

        &__header {
            font-size: 20px;
            font-weight: 700;
            text-transform: capitalize;
            font-family: $base-font;

            @include respond-below(md) {
                margin-top: 30px;
            }
        }

        &__description {
            text-align: center;
        }
    }
}