@import "styles/_styles";

.uploader {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: 100%;
    height: 14em;
    margin-left: 0;
    background-color: transparent;
    border: 1px solid $color-form-control;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    text-align: center;

    &__error {
        border: 1px solid $color-danger;
    }

    &__label {
        display: block;
        margin: 0 0 5px 0;
        font-family: $base-font;
        font-size: 14px;
        color: $color-dark;

        &__error {
            color: $color-danger;
        }
    }

    &__input {
        font-size: 14em;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        cursor: pointer;
    }

    &.dark {
        background-color: rgba(0,0,0,0.1) !important;
    }

    @include respond-below(md) {
        margin-left: 0;
    }

    &__image {
        height: 12em;
        width: auto;
    }

    &__icon {
        width: 4em;
        cursor: pointer;
    }

    &__file-label {
        font-size: 14px;
        opacity: 0.5;
        font-weight: bold;
        text-transform: capitalize;
    }

    &__file {
        align-self: center;
    }

    &__file-label {
        margin-bottom: 0;
        font-size: 13px;
        color: $active-form-color;
    }
    &__file-name {
        color: $color-primary;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 0;
    }

    button {
        color: $color-primary;
        background-color: white;
        padding: 10px 20px;
        font-size: 15px;
        font-weight: bold;
        box-shadow: 0 0 5px 0px $active-form-color;
        cursor: pointer !important;
        border-radius: 10px;

        @include respond-below(md) {
            padding: 10px;
            font-size: 14px;
        }
        
        input {
            font-size: 14px !important;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            cursor: pointer;
        }
    }

    &__error {
        &__wrapper {
            border: 1px solid $color-danger;
        }

        &__label {
            color: $color-danger;
        }
    }

    &__caption {
        font-size: 13px;
        margin-top: 5px;
        color: $color-orange;
        font-weight: bold;

        &__error {
            color: $color-danger;
        }
    }
}