@import "styles/_styles";

.input {

    &__filter {
        margin: 10px;
        position: relative;
        min-width: 20em;

        @include respond-below(md) {
            margin: 0;
            min-width: 100%;
        }
        
        &__label {
            display: block;
            margin: 0 0 5px 0;
            font-family: $base-font;
            font-size: 14px;
            color: $color-dark;

            &__error {
                color: $color-danger;
            }
        }

        &__control {
            width: 100%;
            border-radius: 8px;
            border: 1px solid $color-light-grey;
            padding: 12px;
            font-family: $base-font;
            font-size: 15px;
            color: $color-dark;
            
            @include respond-below(md) {
                padding: 10px;
                font-size: 13px;
            }

            &:read-only {
                background-color: #f2f2f2;
            }
    
            &:focus {
                outline: none;
                border: 1px solid $color-border-input;
            }

            &__password {
                font: small-caption;
                font-size: 16px;
            }
        }

        &__icon {
            position: absolute;
            width: 18px;
            height: 18px;
            z-index: 9;

            @include respond-below(md) {
                width: 16px;
                height: 16px;
            }

            &__label {
                font-size: 12px;
                color: $color-dark;
                margin: 0;
            }

            &__button {
                background-color: transparent;
                border: none;
                margin-top: 10px !important;

                &__suffix {
                    right: 20px !important;
                }

                &__prefix {
                    left: 10px !important;
                }

                img {
                    width: 25px;
                    height: 25px;
                }
            }

            &__prefix {
                left: 15px;
                margin-top: 15px;

                @include respond-below(md) {
                    margin-top: 12px;
                    left: 12px;
                }
                
                ~ .input {
                    &__filter {
                        &__control {
                            padding-left: 40px;

                            @include respond-below(md) {
                                padding-left: 35px;
                            }
                        }
                    }
                }
            }

            &__suffix {
                right: 15px;
                margin-top: 14px;
                
                ~ .input {

                    &__filter {
                        &__control {
                            padding-right: 80px;
                        }
                    }
                }
            }
        }
    }

}