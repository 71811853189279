@import "styles/_styles";

.form {

    &__group {
        @include flex-row(flex-start, center);
        flex-wrap: wrap;
        flex-grow: 1;
    }

    &__wrapper {
        width: 680px;
        display: block;
        margin: 10px auto;

        @include respond-below(md) {
            width: calc(100% - 30px) !important;
            margin: 15px;
        }

        &__header {
            background: $color-secondary;
            padding: 23px;
            text-align: center;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            
            @include respond-below(md) {
                padding: 15px;
            }

            &__title {
                color: $color-light;
                font-weight: 600;
                font-family: $base-font;
                font-size: 20px;
                margin: 0;
                letter-spacing: 1px;
                text-transform: uppercase;

                @include respond-below(md) {
                    font-size: 15px;
                }
            }

            ~ .form {
                &__wrapper {
                    &__body {
                        border-top-left-radius: 0;
                        border-top-right-radius: 0;
                    }
                }
            }
        }

        &__body {
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
            padding: 40px 60px;
            border-radius: 10px;

            @include respond-below(md) {
                padding: 25px 20px;
            }
        }
    }

}