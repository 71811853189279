@import "styles/_styles";

.toast {

    &__container {
        border-radius: 10px !important;
        padding: 20px !important;
        position: absolute !important;
        top: 100px !important;
        right: 0;
        width: 25em;

        @include respond-below(md) {
            top: 0 !important;
            bottom: 0px !important;
            width: 100%;
            min-height: 70px !important;
            position: relative !important;
            margin-left: auto;
            border-radius: 0 !important;
        }

        .Toastify__close-button {
            position: absolute;
            top: 15px;
            right: 15px;

            svg {
                width: 20px;
                height: 20px;
            }
        }

        &__body {
            font-family: $font-family;
            font-size: 14px;
            padding-right: 25px;
        }
    }

}