.footer {
  background-image: url('../../common/images/Footer bg.png');
  width: 100%;
  height: 490px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  clear: both;
}

.blue-bg {
  background: rgba(34, 86, 120, 0.95);
  height: 336px;
  width: 1348px;
  border-radius: 18px;
  text-align: center;
}

.footer-top {
  height: 90%;
  width: 100%;
}

.footer-bottom {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #FFFCFC;
  font-size: 20px;
  height: 10%;
  width: 100%;
}

.footer-logo {
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-logo img {
  height: 76px;
  width: 162px;
}

.footer-content {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.cont-left,
.cont-mid,
.cont-right {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #FFFFFF;
  height: 100%;
  width: 33%;
}

.cont-left {
  text-align: left;
}

.cont-left a {
  display: block;
}

.cont-mid {
  text-align: left;
}

.cont-right {
  text-align: left;
}

.address-wrapper,
.mail-wrapper,
.phone-wrapper,
.whatsapp-wrapper {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.foot-address,
.foot-mail,
.foot-phone,
.foot-whatsapp {
  margin-left: 10px;
}

.footer {
  display: flex;
  flex-direction: column;
}

.footer-top {
  display: flex;
}

.footer-content {
  display: flex;
  justify-content: space-evenly;
  padding-top: 70px;
}

.footer a {
  color: #FFFFFF;
  text-decoration: none;
}

.footer-content img {
  padding-right: 20px;
  vertical-align: middle;
}

.cont-left a:hover {
  text-decoration: underline;
}

/* Responsiveness */
@media (max-width: 1200px) {
  .blue-bg {
      width: 95%;
  }

  .footer-content {
      padding-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .footer-logo img {
      height: 60px;
      width: 130px;
  }

  .footer-bottom {
      font-size: 14px;
  }
}

@media (max-width: 992px) {
  .footer {
      height: auto;
      padding: 10px;
      padding-bottom: 80px;
  }

  .blue-bg {
      height: auto;
      padding: 20px 0;
  }

  .footer-top {
      flex-direction: column;
      align-items: center;
  }

  .footer-logo img {
      height: 50px;
      width: 110px;
  }

  .footer-content {
      padding-top: 10px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  }

  .cont-left,
  .cont-mid,
  .cont-right {
      width: 100%;
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }

  .address-wrapper,
  .mail-wrapper {
      justify-content: center;
      text-align: center;
  }

  .foot-address,
  .foot-mail {
      margin-left: 0;
  }

  .footer-bottom {
      font-size: 12px;
  }

  /* .extra-space{
    height: 60px;
  } */
}
