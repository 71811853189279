@import url('https://fonts.googleapis.com/css2?family=Hammersmith+One&family=Playfair+Display:wght@400;700&family=Poppins:wght@400;500;600&display=swap');

.faq-header {
    background: url('../../common/images/PP-Banner.png') no-repeat center center;
    background-size:cover;
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* margin-top: 40px; */

}
.faq-header h1{
    color: white;
    font-weight: 500;
    font-family: "Hammersmith One", sans-serif;
    font-size: 60px;
    margin-left: 100px;
    max-width: 500px;
}

.faq-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    background: linear-gradient(to right, #d8e5f3, #bfd5ef);
    /* padding: 50px 0; */
    padding-bottom: 50px;
    animation: fadeIn 1.5s ease-in-out;
}

.faq-wrap {
  background: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  width: 90%;
  transform: translateY(30px);
  opacity: 0;
  margin-top: 40px;
  animation: slideUpFadeIn 1s forwards;
}

.faq-item {
    border-bottom: 1px solid #ddd;
    transition: all 0.3s ease;
}

.faq-item:last-child {
    border-bottom: none;
}

.faq-title {
    background-color: #f7f7f7;
    padding: 15px 20px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    transition: background-color 0.3s ease;
}

.faq-title:hover {
    background-color: #e0e0e0;
}

.faq-title h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    font-family: "Hammersmith One", sans-serif;
}

.faq-title span {
    font-size: 24px;
    font-weight: bold;
}

.faq-content {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    margin-top: 10px;
    animation: fadeIn 0.5s ease-out;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.faq-content p {
    margin: 0;
    font-size: 16px;
    color: #555;
    line-height: 1.6;

}

/* Responsive Styles */
@media (max-width: 768px) {
    .faq-header {
        height: 200px;
        justify-content: center;
        margin-top: -34px;
    }

    .faq-header h1 {
        color: black;
        font-size: 40px;
        margin-left: 20px;
    }

    .faq-container{
        padding-bottom: 0px;
    }

    .faq-wrap {
        width: 95%;
        padding: 20px;
        margin-top: 0px;
    }

    .faq-title h2 {
        font-size: 16px;
    }

    .faq-title span {
        font-size: 20px;
    }

    .faq-content p {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .faq-header {
        height: 65px;
    }

    .faq-header h1 {
        font-size: 24px;
        margin-left: 10px;
    }

    .faq-container{
        padding-bottom: 0px;
    }

    .faq-wrap {
        width: 100%;
        padding: 15px;
        margin-top: 0px;
    }

    .faq-title h2 {
        font-size: 14px;
    }

    .faq-title span {
        font-size: 18px;
    }

    .faq-content p {
        font-size: 12px;
    }
}