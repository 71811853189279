@import "styles/_styles";

.input {

    &__emojies {
        @include flex-row(center, center);

        &__btn {
            padding: 0;
            background-color: transparent;
            border: none;
            outline: none;
            box-shadow: none;
            margin: 0 5px;
        }
    }

}