@import "styles/_styles";

.sidenav {
    height: 100%;
    min-height: calc(100vh - 95px);
    border-right: 1px solid $color-light-grey;
    padding-top: 0px;
    padding-bottom: 10px;

    @include respond-below(md) {
        position: fixed;
        bottom: 0;
        height: auto;
        width: 100%;
        min-height: auto;
        background-color: $color-light;
        padding-bottom: 0;
        left: 0;
        z-index: 9999;
        border-top: 1px solid $color-light-grey;
    }

    &__container {

        &__menu {
            @include flex-row(flex-start, center);
            padding: 10px 15px;
            width: 100%;
            background: transparent;
            border: none;
            // border-bottom: 1px solid $color-light-grey;

            &.active {
                // background-color: $color-light-grey !important;
                &::before {
                    position: absolute;
                    left: 0;
                    content: "";
                    display: block;
                    width: 5px;
                    height: 50px;
                    background-color: $color-primary;
                    border-top-right-radius: 7px;
                    border-bottom-right-radius: 7px;

                    @include respond-below(md) {
                        display: none;
                    }
                }
            }

            &__icon {
                margin-right: 20px;
                width: 1.6em;

                @include respond-below(md) {
                    margin-right: 0;
                    margin-bottom: 5px;
                    width: 22px;
                    height: 22px;
                }
            }

            &__title {
                margin: 0;
                font-size: 15px;
                font-family: $font-family;
                color: $color-dark;
                font-weight: $font-semi-bold;
                text-transform: capitalize;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                @include respond-below(md) {
                    font-size: 12px;
                }
            }

            &:hover {
                text-decoration: none;
            }
        }
    }
}