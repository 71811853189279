@import "styles/_styles";

.accordion {

    @include respond-below(md) {
        @include flex-row(space-around, center);
        width: auto !important;
    }

    &__container {
        width: 100% !important;
        border-bottom: 1px solid $color-light-grey !important;

        &:last-child {
            border-bottom: none !important;
        }

        @include respond-below(md) {
            border-bottom: none !important;
            width: auto !important;
        }
    }

    &__header {
        position: relative;
        @include flex-row(flex-start, space-between);
        width: 100% !important;
        padding: 20px !important;
        background-color: transparent !important;
        border: none !important;
        border-radius: 0 !important;

        @include respond-below(md) {
            padding: 10px !important;
        }

        &:hover, &__active {
            background-color: $color-light-grey !important;
        }

        &__label {
            @include flex-row(flex-start, center);
            width: 100%;

            @include respond-below(md) {
                display: block !important;
                text-align: center;
                width: auto;
            }
        }

        &:focus {
            outline: none !important;
            box-shadow: none !important;
        }

        &:focus, &:visited, &:hover, &:active {
            text-decoration: none !important;
        }

        &.active {
            background-color: $color-light-grey !important;
            .arrow {
                content: url("../../images/icon/up-arrow.png");
            }
        }

        .arrow {
            position: absolute;
            right: 25px;
            width: 15px;
            margin-top: 8px;
            content: url("../../images/icon/down-arrow.png");

            @include respond-below(md) {
                display: none;
            }
        }
    }

    &__collapse {
        margin-left: 3em;

        &:hover {
            background-color: $color-light-grey !important;
        }

        &:last-child {
            margin-bottom: 15px;
        }

        @include respond-below(md) {
            margin: 0;

            &:first-child {
                padding-top: 5px;
            }

            &:last-child {
                margin-bottom: 0;
                padding-bottom: 5px;
            }

            .sidenav {
                &__container {
                    &__menu {
                        &__title {
                            color: $color-light;
                        }
                    }
                }
            }
        }

        &__wrapper {
            @include respond-below(md) {
                position: absolute;
                bottom: 68px;
                background-color: $color-dark;
                width: 80%;
                left: 10%;
                border-radius: 10px;
            }
        }
    }
}