
@import "styles/_styles";

.modal {

    width: 100% !important;

    @include respond-below(md) {
        top: 0 !important;
        z-index: 9999 !important;
    }
    
    &__medium {
        .modal-dialog {
            max-width: 550px;
            width: 550px;

            @include respond-below(md) {
                width: 100%;
                max-width: 96%;
            }

            .modal-content {
                border-radius: 10px;
                padding: 40px;

                @include respond-below(md) {
                    padding: 40px 25px;
                }

                .modal-header {
                    display: block !important;
                    position: relative;
                    text-align: center;
                    border: none !important;
                    padding: 0 1em !important;
                    margin-bottom: 10px !important;

                    &.no-padding {
                        padding: 0 !important;
                    }

                    .modal-title {
                        font-size: 18px;
                        font-weight: $font-bold;
                        text-transform: capitalize;
                        margin-bottom: 8px;

                        @include respond-below(md) {
                            font-size: 16px;
                        }
                    }

                    .modal-description {
                        font-size: 15px;
                        margin-bottom: 0;

                        @include respond-below(md) {
                            font-size: 14px;
                        }
                    }

                    .modal-closer {
                        position: absolute;
                        background: transparent !important;
                        border: none !important;
                        right: -1em;
                        top: -1em;

                        @include respond-below(md) {
                            top: -1.5em;
                            right: -0.5em;
                        }
                        
                        img {
                            width: 18px;
                            height: 18px;
                        }
                    }
                }

                .modal-body {
                    padding: 0 !important;
                }
            }
        }
    }

    &.fade {
        transition: opacity .30s linear;
    }

    &__full {
        .modal-dialog {
            max-width: 1140px;
            width: 100%;
            
            @include respond-below(md) {
                max-width: 100%;
                width: 96%;
            }

            .modal-content {

                @include respond-below(md) {
                    border-radius: 10px !important;
                }

                .modal-body {
                    padding: 0 !important;

                    @include respond-below(md) {
                        margin: 5px !important;
                    }
                }
            }
        }
    }

    &__grey {
        .modal-content {
            background: $color-disabled !important;
        }
    }
}