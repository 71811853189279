@mixin flex-row($justify_content, $align-item) {
    display: flex !important;
    flex-direction: row ;
    justify-content: $justify-content ;
    align-items: $align-item ;
} 

@mixin flex-column($justify_content, $align-item) {
    display: flex !important;
    flex-direction: column ;
    justify-content: $justify-content ;
    align-items: $align-item ;
} 

@mixin inline-flex-column($justify_content, $align-item) {
    display: inline-flex ;
    flex-direction: column ;
    justify-content: $justify-content ;
    align-items: $align-item ;
} 

@mixin flex-self($align-self) {
    align-self: $align-self ;
}