@import "styles/_styles";

.button {
    border: none;
    padding: 15px;
    margin: 10px;
    border-radius: 8px;
    text-transform: uppercase;
    font-weight: 700;
    font-family: $font-family;

    @include respond-below(md) {
        padding: 10px;
    }


    @include flex-row(center, center);

    &__md {
        min-width: 240px;
        font-size: 15px;

        @include respond-below(md) {
            min-width: 200px;
            font-size: 12px;
        }
    }

    &__sm {
        min-width: 120px;
        font-size: 14px;

        @include respond-below(md) {
            min-width: 100px;
            font-size: 12px;
        }
    }

    &__lg {
        min-width: 100%;
        font-size: 15px;

        @include respond-below(md) {
            font-size: 12px;
            min-width: 100%;
        }
    }

    &__warning {
        background: $color-orange;
        color: $color-light;
        border: 1px solid $color-orange;

        &__outline {
            background: transparent;
            border: 1px solid $color-orange;
            color: $color-orange;
        }

        &__active {
            background: $color-orange;
            color: $color-light;
            border: 1px solid $color-orange;
        }

        &__secondary {
            background: $color-warning-secondary;
            color: $color-orange;
            border: 1px solid $color-warning-secondary;
        }
    }

    &__success {
        background: $color-button-success-gradient;
        color: $color-light;
        border: 1px solid $color-success;

        &__secondary {
            background: $color-success-secondary;
            color: $color-success-primary;
            border: 1px solid $color-success-secondary;
        }

        &__outline {
            background: transparent;
            border: 1px solid $color-success;
            color: $color-success;
        }

        &__active {
            background: $color-success;
            color: $color-light;
            border: 1px solid $color-success;
        }
    }

    &__danger {
        background: $color-button-danger-gradient;
        color: $color-light;
        border: 1px solid $color-button-danger-gradient;

        &__outline {
            background: transparent;
            border: 1px solid $color-danger;
            color: $color-danger;
        }

        &__active {
            background: $color-danger;
            color: $color-light;
            border: 1px solid $color-danger;
        }
    }

    &__default {
        background: transparent;
        color: $color-dark;
        border: 1px solid transparent;

        &__secondary {
            background: $color-default;
            color: $color-dark;
            border: 1px solid $color-default;
        }

        &__outline {
            background: transparent;
            border: 1px solid $color-default;
            color: $color-default;
        }

        &__active {
            background: $color-default;
            color: $color-dark;
            border: 1px solid $color-default;
        }
    }

    &__primary {
        background: $color-primary-gradient;
        color: $color-light;

        &__secondary {
            background: $color-primary-secondary;
            color: $color-primary-primary;
        }

        &__outline {
            background: transparent;
            border: 1px solid $color-primary;
            color: $color-primary;
        }

        &__active {
            background: $color-primary;
            color: $color-light;
        }
    }

    &__icon {
        width: 20px;
        height: 20px;

        @include respond-below(md) {
            width: 14px;
            height: 14px;
        }

        &__after-text {
            margin-left: 8px;
        }

        &__before-text {
            margin-right: 8px;
        }
    }

    &__group {
        &__right {
            @include flex-row(flex-end, center);
            align-self: flex-end;
        }
        &__center {
            @include flex-row(center, center);
            align-self: center;
        }
        &__left {
            @include flex-row(flex-start, center);
            align-self: flex-start;
        }
    }

    &__social {
        border: 1px solid $color-primary-transparent !important;
        box-shadow: none !important;
        padding: 12px 18px !important;
        border-radius: 8px !important;
        margin: 10px;

        &__google {
            span {
                padding: 0 !important;
                @include flex-row(center, center);
            }
        }

        &__facebook {
            background-color: transparent;
            font-size: 15px;
            @include flex-row(center, center);

            &::before {
                content: url('../../images/icon/facebook-icon.png');
                width: 20px;
                height: 20px;
                margin-top: -5px;
                margin-right: 10px;
            }
        }

        &__text {
            margin: 0;
            font-size: 15px;
            color: $color-dark;
            font-weight: 400;
        }

        &__icon {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            margin-top: -2px;
        }
    }

    &__dashboard {
        @include respond-below(md) {
            position: fixed;
            left: 0;
            bottom: 65px;
            width: 100%;

            button {
                margin: 0;
                border-radius: 0;
                width: 100%;
                padding: 12px 10px;
            }
        }
    }
}