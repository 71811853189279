@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

$font-family: 'Montserrat', sans-serif;
$font-secondary: 'Source Sans Pro', sans-serif;
$base-font: 'Montserrat', sans-serif;

$font-normal: 400;
$font-semi-bold: 500;
$font-bold: 600;
$font-extra-bold: 700;
$font-smoth: 200;

body, h1, h2, h3, h4, h5, h6, p, span, label {
    font-family: $font-family;
}


// New color edition
$color-orange: #F18200;
$color-button-success-gradient: linear-gradient(142.26deg, #3DD598 33.63%, #7ABDEE 151.48%);
$color-button-primary-gradient: linear-gradient(360deg, #1B4A69 0%, #D8EAF3 366.67%);
$color-button-danger-gradient: linear-gradient(360deg, #F14336 0%, #D8EAF3 366.67%);
$color-button-secondary-gradient: linear-gradient(360deg, #2FACF3 0%, #D8EAF3 366.67%);
$color-button-orange-gradient: linear-gradient(180.4deg, #F18200 0.33%, #FFF8B9 663.84%);
$color-primary-gradient: linear-gradient(181.98deg, #1B4A69 1.58%, #4592C5 254.94%);

$color-success-secondary: #E3FBF5;
$color-success-primary: #1DC39A;
$color-primary-secondary: #E4EFFD;
$color-primary-primary: #4592C5;
$color-warning-secondary: #FFB573;
$color-danger-secondary: #ffebe6;

$color-primary: #1a4057;
$color-secondary: #1b4a69;
$color-transparent: #d8eaf3;
$color-disabled: #F7F7F7;
$color-light-grey: #D8D8D8;
$color-default: #e6e6e6;
$color-border-input: #79A7C5;
$color-form-control: #79A7C5;

$color-grey: #979797;
$color-light: #ffffff;
$color-dark: #2E2E2E;
$color-success: #3DD598;
$color-danger: #F14336;
$color-light-danger: rgba(241, 67, 54, 0.2);
$color-overlay: rgba(0,0,0,0.5);
$color-dark-grey: #92929D;
$color-form-filled: #F4F4F4;
$color-checkbox-label-description: #656565;
$color-light-success: #E3FBF5;
$color-light-primary: #E4EFFD;
$color-primary-transparent: #D8EAF3;
$color-switcher-profile: #F4FBFF;
$color-switcher-border: #DDEBFD;
$placeholder-color: #495057;
$active-form-color: #8b99a7;
$border-color: #e6e6e6;
$success-color: #07bc0c;
$error-color: #e74c3c;
$error-secondary: #e95949;
$table-border-color: #DCDCE0;
$label-color: #5A5A5A;
$detail-bg-color: #f6f6f6;
$table-border-color: #f2f2f2;
$indicator-color: #979797;
$upload-btn-color: #9D9D9D;