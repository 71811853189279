@import "styles/_styles";

.bid-detail {

    &__item {
        margin-bottom: 10px;
    }


    &__button-group {
        margin-top: 40px;
    }

    &__assign {
        margin: 0;
    }
}