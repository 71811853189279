@import url('https://fonts.googleapis.com/css2?family=Hammersmith+One&family=Playfair+Display:wght@400;700&family=Poppins:wght@400;500;600&display=swap');

.toggleCheckbox:checked + .toggleContainer::before {
  left: 50%;
}

.toggle-label {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 230px;
  border: 3px solid black;
  border-radius: 20px;
  background: #225678;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  cursor: pointer;
}

/* Slider Position */
.toggle-label::before {
  content: '';
  position: absolute;
  width: 50%;
  height: 100%;
  left: 0;
  border-radius:20px;
  background: white;
  transition: all 0.3s;
}

/* Partner Position */
.toggleCheckbox:checked + .toggle-label::before {
  left: 50%;
}

.toggle-label div {
  padding: 6px;
  text-align: center;
  z-index: 1;
}

.toggleCheckbox {
  display: none;
}

/* User Inactive */
.toggleCheckbox:checked + .toggle-label div:first-child{
  color: white;
  transition: color 0.3s;
}

/* Partner Active */
.toggleCheckbox:checked + .toggle-label div:last-child{
  color: black;
  transition: color 0.3s;
}

/* User Active */
.toggleCheckbox + .toggle-label div:first-child{
  color: black;
  transition: color 0.3s;
}

/* Partner Inactive */
.toggleCheckbox + .toggle-label div:last-child{
  color: white;
  transition: color 0.3s;
}