@import "styles/_styles";

.input {

    &__picker {
        width: 100%;

        &__control {
            width: 100%;
            padding: 15px;
            border-radius: 8px;
            border: 1px solid $color-light-grey;
            font-family: $base-font;
            font-size: 15px;

            @include respond-below(md) {
                padding: 12px;
                font-size: 14px;
            }

            &:focus {
                outline: none;
                border: 1px solid $color-border-input;
            }

            &__error {
                color: $color-danger;
                border: 1px solid $color-danger;

                &::placeholder {
                    color: $color-danger;
                }

                &:focus {
                    border: 1px solid $color-danger;
                }
            }

            &__with-icon {
                &__prefix {
                    padding-left: 50px;
                }
                &__suffix {
                    padding-right: 50px;
                }
            }
        }

        &__popper {
            margin: 0 !important;
            z-index: 99999 !important;
        }

        &__calendar {
            width: 100%;

            @include respond-below(md) {
                @include flex-row(center, center);
            }

            .react-datepicker {
                &__triangle {
                    display: none;
                }
                &__header {
                    background-color: $color-primary;
                }
                &__current-month {
                    color: $color-light;
                }
                &__day-name {
                    color: $color-light;
                }
                &__day {
                    font-family: $base-font;

                    &--selected, &--keyboard-selected {
                        background-color: $color-primary !important;
                    }
                }
                &__time-list-item {
                    font-family: $base-font;

                    &--selected {
                        background-color: $color-primary !important;
                    }
                }
                &__navigation {
                    &--next {
                        border-left-color: $color-light;

                        &:focus, &:visited, &:hover {
                            border-left-color: $color-light;
                        }
                    }
                    &--previous {
                        border-right-color: $color-light;

                        &:focus, &:visited, &:hover {
                            border-right-color: $color-light;
                        }
                    }
                }
            }
            .react-datepicker-time {
                &__header {
                    color: $color-light;
                }
            }
        }
    }

}